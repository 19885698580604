/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    code: "code",
    pre: "pre",
    h3: "h3",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "There are many reasons you might want to push code via ssh but under different identities or profiles."), "\n", React.createElement(_components.h2, null, "TLDR"), "\n", React.createElement(_components.p, null, "Easily pushing up changes to one of the many git solutions is an essential part of any workflow and not likely to change anytime soon. Setting up ssh between local env and github, bitbucket etc is one of the first things we figure out on the developer journey. There may come a time when you don't have a separate machine setup for work or maybe you want to be able to push some code from your personal machine. Or you have a open source profile seperate from your professional profile."), "\n", React.createElement(_components.h2, null, "Create ssh key pairs for each service or machine"), "\n", React.createElement(_components.p, null, "Navigate to ", React.createElement(_components.code, null, "~/.ssh"), " and generate a new private, public key pair."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "ssh-keygen -t rsa \n")), "\n", React.createElement(_components.p, null, "You will be prompted to name the file, the convention that makes the most sense for me is ", React.createElement(_components.code, null, "<identifier>.<domain>.key"), " for example ", React.createElement(_components.code, null, "personal.github.key"), " or ", React.createElement(_components.code, null, "organization.domain.key"), "."), "\n", React.createElement(_components.h3, null, "Associate Domain, host with key"), "\n", React.createElement(_components.p, null, "Navigate to ", React.createElement(_components.code, null, "~/.ssh/config"), ", if the file does not exist make it or open it in your favorite text editor. Add a config for each account."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt:title=config"
  }, "...\n##\n## GITHUB\n##\n## Github Personal \nHost personal.github.com \n    HostName github.com\n    User git\n    AddKeysToAgent yes\n    IdentityFile ~/.ssh/personal.github.key\n    IdentitiesOnly yes\n## Github <some-organization>\nHost <organization>.github.com\n    HostName github.com\n    User git\n    AddKeysToAgent yes\n    IdentityFile ~/.ssh/<organization>.github.key\n...\n")), "\n", React.createElement(_components.h2, null, "Add a custom domain to the local git repo config"), "\n", React.createElement(_components.p, null, "When you ", React.createElement(_components.code, null, "git init"), " a repo it creates a ", React.createElement(_components.code, null, "./.git/"), " in this we can"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "[core]\n\trepositoryformatversion = 0\n\tfilemode = true\n\tbare = false\n\tlogallrefupdates = true\n\tignorecase = true\n\tprecomposeunicode = true\n[remote \"origin\"]\n\turl = git@lucaszapico.github.com:LucasZapico/.git\n\tfetch = +refs/heads/*:refs/remotes/origin/*\n[pull]\n\trebase = true\n\n")), "\n", React.createElement(_components.h2, null, "Example of my ssh config"), "\n", React.createElement(_components.p, null, "Note the ip's are faked."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt:title=config"
  }, "##\n## GITHUB\n##\n## Github Personal \nHost personal.github.com \n    HostName github.com\n    User git\n    AddKeysToAgent yes\n    IdentityFile ~/.ssh/personal.github.key\n    IdentitiesOnly yes\n## Github <some-organization>\nHost <organization>.github.com\n    HostName github.com\n    User git\n    AddKeysToAgent yes\n    IdentityFile ~/.ssh/<organization>.github.key\n## Vultr Admin\nHost Vultr.com\n    HostName <host-ip>\n    IdentityFile ~/.ssh/personal.vultr.key \n##\n## LOCAL NETWORK MACHINES\n##\nHost 198.152.0.10\n    IdentityFile ~/.ssh/machine1.local.key\n# proxmox \nHost 198.152.1.341\n    IdentityFile ~/.ssh/host.prox1.key\n# Fig ssh integration. Keep at the bottom of this file.\nMatch all\n  Include ~/.fig/ssh\n\n##\n## REFERENCE\n##\n# Make new key pair\n# `ssh-keygen -t rsa`\n# `ssh-keygen -t ed25519 -C \"your_email@example.com\"`\n#\n#\n\n")), "\n", React.createElement(_components.h4, null, "Test Connections"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "ssh -T git@personal.github.com\n")), "\n", React.createElement(_components.h3, null, "Reference"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
