import GATSBY_COMPILED_MDX from "/opt/build/repo/src/content/articles/repo-management-with-mutliple-git-account.mdx";
import {Link as GatsbyLink} from "gatsby";
import React from "react";
import {Grid, GridItem, Box, Container, Heading, Flex, Text, Tag} from "@chakra-ui/react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {generate} from "shortid";
import {BreadCrumbGroup, HalfByHalfSection, LinkOne, MdxTOC} from "components";
import MDXLayout from "components/base/layout/mdx-layout";
function ArticleTemplate({children, path, pageContext, location}) {
  const {next, previous, node, title} = pageContext;
  const {tableOfContents, fields} = node;
  let imageNext = null;
  let imagePrev = null;
  if (next?.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData) {
    imageNext = next.featuredImage.node.localFile.childImageSharp.gatsbyImageData;
  }
  if (previous?.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData) {
    imagePrev = previous.featuredImage.node.localFile.childImageSharp.gatsbyImageData;
  }
  const NextArticle = () => React.createElement(Box, {
    key: generate()
  }, React.createElement(Box, {
    to: `/${next.fields.path}`,
    as: GatsbyLink
  }, React.createElement(Box, null, imageNext && React.createElement(GatsbyImage, {
    alt: "",
    image: imageNext
  })), React.createElement(Box, {
    p: {
      base: 5,
      md: 10
    }
  }, React.createElement(Heading, {
    as: "h4",
    size: "lg"
  }, next.frontmatter.title))));
  const PrevArticle = () => React.createElement(Box, {
    key: generate()
  }, React.createElement(Box, {
    as: GatsbyLink,
    to: `/${previous.fields.path}`
  }, React.createElement(Box, null, imagePrev && React.createElement(GatsbyImage, {
    alt: "",
    image: imagePrev
  })), React.createElement(Box, {
    p: {
      base: 5,
      md: 10
    }
  }, React.createElement(Heading, {
    as: "h4",
    size: "lg"
  }, previous.frontmatter.title))));
  return React.createElement(React.Fragment, null, React.createElement(Box, {
    minHeight: "100vh"
  }, React.createElement(Container, {
    maxW: "container.xl"
  }, React.createElement(Grid, {
    templateColumns: "repeat(12, 1fr)",
    gap: {
      base: 0,
      lg: 6
    }
  }, React.createElement(Box, {
    mx: "auto",
    pt: 40,
    pb: 20,
    as: GridItem,
    colSpan: {
      base: 12,
      lg: 8
    }
  }, React.createElement(Box, {
    px: {
      base: 0,
      md: 4,
      lg: 4
    },
    maxW: {
      md: "650px"
    }
  }, React.createElement(Heading, {
    mt: 6,
    mb: 4,
    as: "h1",
    size: "2xl"
  }, title), React.createElement(BreadCrumbGroup, {
    size: "sm",
    pathArr: ["articles", title]
  }), React.createElement(Flex, {
    flexWrap: "wrap",
    py: 6
  }, node.frontmatter.categories && node.frontmatter.categories.map((cat, i) => React.createElement(Tag, {
    mr: 1,
    mb: 1,
    variant: "sec",
    key: generate()
  }, " #", cat))), React.createElement(MDXLayout, null, React.createElement(Box, {
    py: 10
  }, children)))), React.createElement(Box, {
    as: GridItem,
    display: {
      base: "none",
      xl: "block"
    },
    colSpan: {
      base: 0,
      lg: 4
    }
  }, React.createElement(MdxTOC, {
    tableOfContents: tableOfContents,
    pagePath: path,
    width: "20%"
  })))), React.createElement(Container, {
    maxW: "container.xl",
    my: 10
  }, React.createElement(Box, {
    my: 10
  }, React.createElement(Heading, {
    as: "h3",
    size: "xl"
  }, " More ", node.frontmatter.type === "note" ? "Notes" : "/Articles"), node.frontmatter.type === "note" ? React.createElement(LinkOne, {
    as: GatsbyLink,
    to: "/notes"
  }, " All Notes") : React.createElement(LinkOne, {
    as: GatsbyLink,
    to: "/articles"
  }, " All Articles")), React.createElement(HalfByHalfSection, {
    right: previous !== null ? React.createElement(PrevArticle) : "",
    left: next !== null ? React.createElement(NextArticle) : ""
  }))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ArticleTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
